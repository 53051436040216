import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  display:flex;
  align-items: center;
  width: 452px;
  height: 78px;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  margin: 10px 0px 16px 0px;
`

export const IconSection = styled.div`
  display: flex;
  justify-content: center;
  width: 75px;
  align-items: center;
  border-right: 1px solid #efefef;
  height: 100%;
`

export const AnnouncementSection = styled.div`
  height: 78px;
  display: flex;
  padding: 5px 30px 5px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    font-family: ${theme.fonts.secondary};
    color: ${theme.colors.purple};
    font-size: 16px;
    font-weight: bold;
    margin: 0;
  }
`
