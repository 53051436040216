import React, { useContext } from 'react'
import { useQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'
import { ThemeContext } from 'styled-components'
import { useGlobalContext } from '../../utils/globalContext'
import InstructorWidget from './components/InstructorWidget'
import Header from '../../components/HeaderText'
import JoinTeamForm from './components/JoinOurTeamForm'
import {
  PageContainer, SubHeader, InstructorHeaders, InstructorContainer,
} from './styles'
import ALL_INSTRUCTORS from './graphql'


const OurTeam = () => {
  const { language } = useGlobalContext()
  const {
    data, loading, error,
  } = useQuery(ALL_INSTRUCTORS, {
    variables: { locale: language },

  })

  const theme = useContext(ThemeContext)


  if (error) {
    return (
      <PageContainer>
        <Header text="Our Team" squiggleColor="blue" />
        <SubHeader>Meet our passionate instructors.</SubHeader>
        <InstructorHeaders>LEADERSHIP</InstructorHeaders>
        <InstructorContainer>
          <InstructorHeaders>Could not retrieve data</InstructorHeaders>
        </InstructorContainer>
        <InstructorHeaders>SUMMER INSTRUCTORS</InstructorHeaders>
        <InstructorContainer>
          <InstructorHeaders>Could not retrieve data</InstructorHeaders>
        </InstructorContainer>
        <JoinTeamForm />
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Header text="Our Team" squiggleColor="blue" />
      <SubHeader>Meet our passionate instructors.</SubHeader>
      <InstructorHeaders>LEADERSHIP</InstructorHeaders>
      {loading
        ? (
          <div style={{
            height: '470px', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <MoonLoader loading color={theme.colors.fonts.primary} />
          </div>
        )
        : (
          <InstructorContainer>
            {data.allInstructors.filter(el => el.isLeadership)
              .map(leader => (<InstructorWidget instructor={leader} key={leader.id} />))}
          </InstructorContainer>
        )}
      <InstructorHeaders>SUMMER INSTRUCTORS</InstructorHeaders>
      {loading
        ? (
          <div style={{
            minHeight: '470px', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <MoonLoader loading color={theme.colors.fonts.primary} />
          </div>
        )
        : (
          <InstructorContainer>
            {
            data.allInstructors.filter(el => !el.isLeadership)
              .map(i => <InstructorWidget instructor={i} key={i.id} />)
}
          </InstructorContainer>
        )}
      <JoinTeamForm />
    </PageContainer>
  )
}

export default OurTeam
