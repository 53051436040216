import React from 'react'

import CircularProgress from '@material-ui/core/CircularProgress'

import { ButtonStyle } from './styles'
import { materialTheme } from '../../materialTheme'

const PrimaryButton = ({
  children, style, onClick, variant, color, loading, ...rest
}) => (
  <ButtonStyle
    variant={variant || 'contained'}
    color={color || 'primary'}
    style={!color && !style ? materialTheme.palette.purpleBackground : style}
    onClick={onClick}
    disableElevation
    {...rest}
  >
    {loading ? <CircularProgress style={{ color: 'white', width: '30px', height: '30px' }} /> : children}
  </ButtonStyle>
)

export default PrimaryButton
