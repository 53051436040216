import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'
import Background from '../../components/Background'
import HeaderText from '../../components/HeaderText'
import {
  Greeting, Container, CourseContainer, TopContainer, ErrorContainer,
} from './styles'
import theme from '../../theme'
import { GET_COURSES, GET_ANNOUNCE } from './graphql'
import { useGlobalContext } from '../../utils/globalContext'
import CourseWidget from '../../components/CourseWidget'
import Announcement from '../../components/AnnouncementWidget'

const time = () => {
  const hour = (new Date()).toLocaleString('en-US', { timeZone: 'Asia/Tokyo', hour: 'numeric', hour12: false })
  if (hour >= 0 && hour < 12) {
    return {
      time: 'm', color1: theme.colors.purple, color2: theme.colors.marine, squiggleColor: theme.colors.marigold, text: 'Good morning,',
    }
  }
  if (hour >= 12 && hour < 18) {
    return {
      time: 'a', color1: theme.colors.purple, color2: theme.colors.peacock, squiggleColor: theme.colors.teal, text: 'Good afternoon,',
    }
  }
  return {
    time: 'n', color1: theme.colors.teal, color2: theme.colors.background.primary, squiggleColor: theme.colors.teal, text: 'Good evening,',
  }
}

const Videos = () => {
  const inputs = time()
  const isNight = inputs.time === 'n'
  const { viewer, language } = useGlobalContext()

  const { data: data1, loading: loading1, error: error1 } = useQuery(GET_COURSES, {
    variables: { locale: language },
  })
  const { data: data2, loading: loading2, error: error2 } = useQuery(GET_ANNOUNCE, {
    variables: { locale: language },
  })
  if (loading1 || loading2) {
    return (
      <Background time={inputs.time}>
        <div style={{ height: '100%', position: 'relative' }}>
          <Greeting night={isNight} />
          <TopContainer>
            <HeaderText
              videos
              text={inputs.text}
              text2={viewer && `${viewer.firstName}.`}
              color1={inputs.color1}
              color2={inputs.color2}
              squiggleColor={inputs.squiggleColor}
            />
          </TopContainer>
          <Container style={{ height: '50vh' }}>
            <MoonLoader loading color={isNight ? 'white' : theme.colors.purple} />
          </Container>
        </div>
      </Background>
    )
  }
  if (error1 || error2) {
    return (
      <Background time={inputs.time}>
        <div style={{ height: '100%', position: 'relative' }}>
          <Greeting night={isNight} />
          <TopContainer>
            <HeaderText
              videos
              text={inputs.text}
              text2={viewer && viewer.firstName}
              color1={inputs.color1}
              color2={inputs.color2}
              squiggleColor={inputs.squiggleColor}
            />
          </TopContainer>
          <ErrorContainer>
            An error has occured. Please try again.
          </ErrorContainer>
        </div>
      </Background>
    )
  }
  return (
    <Background time={inputs.time}>
      <div style={{ height: '100%', position: 'relative' }}>
        <Greeting night={isNight} />
        <TopContainer>
          <HeaderText
            videos
            text={inputs.text}
            text2={`${viewer.firstName}.`}
            color1={inputs.color1}
            color2={inputs.color2}
            squiggleColor={inputs.squiggleColor}
          />
          <Announcement announcement={data2.announcement ? data2.announcement.body : 'No announcements. Check back soon!'} announce={!!data2.announcement} />
        </TopContainer>
        <Container>
          <CourseContainer>
            {data1.allCourses.sort((a, b) => a.title.localeCompare(b.title, undefined, { numeric: 'true' }), 1, -1)
            // {data1.allCourses.slice(1, 3)
              .map(d => (
                <CourseWidget
                  {...d}
                />
              ))}
          </CourseContainer>
        </Container>
      </div>
    </Background>

  )
}
export default Videos
