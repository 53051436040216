import styled from 'styled-components'

export const FooterContainer = styled.div` 
  background-color: ${props => props.theme.colors.background.secondary};
  min-height: 345px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 75px;
`

export const Top = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;
`

export const MailList = styled.div`
  display: flex;
  flex-direction: column;
  text-align: flex-end;
  justify-content: space-around;
  height: 166px;
`

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  text-align: flex-start;
  height: 180px;
  justify-content: space-between;
  margin-bottom: 40px;
`
export const BigText = styled.div`
  font-family:  ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.marine};
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  margin: 0px 0px 10px 0;
`
export const SmallText = styled.a`
  font-family:  ${props => props.theme.fonts.secondary};
  color: ${props => props.theme.colors.marine};
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
`
export const Bottom = styled.div`
  width: 100%;
  min-height: 95px;
  padding: 0px 47px 20px 47px;
  display:flex;
  align-items: flex-end;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.secondary};
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: ${props => props.theme.colors.marine};
`

export const BottomText = styled.div`
  margin-right: ${props => props.margin};
`

export const BottomDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: ${props => props.margin};
  align-self: flex-end;
`

export const SideDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`
export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 160px;
  align-items: center;
`

export const NakedLink = styled.a`
  color: inherit;
  text-decoration: none;
`
