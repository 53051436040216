import React from 'react'

import {
  InstructorCard, LearnMore, InstructorName,
  ShortBio, Content, Actions, Media,
} from './styles'

// may need to replace values later with correct form once CMS is up
const InstructorWidget = ({ instructor }) => (
  <InstructorCard width="260px" height="470px">
    <Media
      width="260"
      component="img"
      image={instructor.profileImage.responsiveImage && instructor.profileImage.responsiveImage.src}
    />
    <Content>
      <InstructorName gutterBottom>{instructor.name}</InstructorName>
      <ShortBio>
        {instructor.bio}
      </ShortBio>
    </Content>
    <Actions>
      <LearnMore href={instructor.learnMoreLink}>LEARN MORE</LearnMore>
    </Actions>
  </InstructorCard>
)

export default InstructorWidget
