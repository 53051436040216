import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

`

export const Header = styled.h1`
  font-family: 'Raleway';
  font-size: 48px;
  color: ${props => props.color || theme.colors.purple};
  font-weight: 800;
  span {
    color: ${props => props.color2}
  }
  margin-bottom: 0;

`

export const TextContainer = styled.div`
  display: flex;
`

export const Squiggle = styled.img`
  margin-top: 2px;
`
