import styled from 'styled-components'
import theme from '../../../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 264px;
  height: 800px;
  padding: 20px 0 50px 0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.19), 0px 6px 6px rgba(0, 0, 0, 0.26);
`

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 25px;
  border-bottom: 1px solid #bfbfbf;
`

export const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 0;
`

export const CourseName = styled.h3`
  color: ${theme.colors.marine};
  font-family: ${theme.fonts.secondary};
  font-size: 16px;
  font-weight: bold;
`

export const CurrentVideo = styled.div`
  display: flex;
  align-items: center;
  margin-left: -7px;

  h3 {
    color: ${theme.colors.peacock};
    font-family: ${theme.fonts.secondary};
    font-size: 16px;
    font-weight: bold;
    margin: 5px;
  }
`

export const Video = styled.div`
  display: flex;
  align-items: center;
  width: 264px;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${props => (props.current ? '#c9c9c9' : 'white')};
  padding: 0 15px 0 20px;
  
  svg {
    margin-right: 15px;
  }

  p {
    color: ${theme.colors.marine};
    font-family: ${theme.fonts.secondary};
    font-size: 14px;
    font-weight: bold;
    margin: 12px 0;

    span {
      font-weight: normal;
    }
  }
`

export const VideoTitle = styled.div`
  display: flex;
  align-items: center;
`
