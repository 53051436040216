/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react'
import HomepageScroll from '../../../assets/images/HomepageScroll.svg'
import { ButtonImage } from './styles'

// I still need the id of whatever is going under so I can link it here.
const HomeScrollButton = () => {
  const handleClick = () => {
    const { top } = document.getElementById('welcome').getBoundingClientRect()
    document.getElementById('app-wrapper').scrollTo({ top, behavior: 'smooth' })
  }
  return (
    <ButtonImage
      src={HomepageScroll}
      alt="scroll down"
      onClick={handleClick}
    />
  )
}
export default HomeScrollButton
