import styled from 'styled-components'

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 107px;
`
export const SubHeader = styled.h2`
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    text-align: center;

    color: ${({ theme }) => theme.colors.fonts.primary};

    margin-top: 49px;
    margin-bottom: 75px;
`
export const InstructorHeaders = styled.h3`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: ${({ theme }) => theme.colors.fonts.primary};

    margin-bottom: 31px;
    margin-top: 9px;
`

export const InstructorContainer = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    /*  
        this width is to set the width to the width of the card
        this can be cahnged if we want something different
     */
    grid-template-columns: repeat(auto-fit, 260px);
    width: 80%;
    column-gap: 25px;
    row-gap: 71px;
    min-height: 333px;
    margin-bottom: 80px;
   
`
