import React from 'react'
import {
  Container, ContentContainer,
} from './styles'
import Footer from '../../components/Footer'

const PageWrapper = ({ children }) => (
  <Container>
    <ContentContainer>
      {children}
    </ContentContainer>
    <Footer />
  </Container>
)

export default PageWrapper
