import React from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  ProfileContainer, CoulmnContainer, SaveButton,
} from './styles'
import InputSelect from '../../../../components/InputSelect'
import useForm from '../../../../utils/useForm'
import { editStudent } from '../../../../utils/yup'
import EditInput from '../../../../components/InputEditable'
import EDIT_STUDENT from './graphql'
import PrimaryButton from '../../../../components/PrimaryButton'

const offsets = [-12, -11, -10, -9.5, -9, -8, -7,
  -6, -5, -4, -3.5, -3, -2, -1, 0, 1, 2, 3, 3.5, 4,
  4.5, 5, 5.5, 5.75, 6, 6.5, 7, 8, 8.75, 9, 9.5, 10,
  10.5, 11, 12, 12.75, 13, 14]

const timeOptions = offsets.map(offs => {
  const intOff = offs * 100
  let label = 'UTC'
  if (intOff > 0) {
    label += '+'
  } else if (intOff === 0) {
    label += '±'
  }
  if (intOff % 100 === 0) {
    return { value: offs, label: `${label}${offs}:00` }
  }
  if (intOff % 50 === 0) {
    return { value: offs, label: `${label}${Math.trunc(offs)}:30` }
  }

  return { value: offs, label: `${label}${Math.trunc(offs)}:45` }
})

const StudentProfileEditor = ({ emailAddress, timeZone }) => {
  const {
    state, dispatch, validate, errors, reset,
  } = useForm({ timeZone, password: '', email: emailAddress }, editStudent)
  const isChanged = (timeZone === state.timeZone && emailAddress === state.email && state.password === '')

  const [
    changeStudent, { loading },
  ] = useMutation(EDIT_STUDENT, {
    variables:
    {
      input: {
        password: state.password,
        email: state.email,
        timeZone: state.timeZone,
      },
    },
    onCompleted: () => reset(),
  })


  const handleEdit = async () => {
    if (await validate()) {
      changeStudent()
    }
  }
  return (
    <ProfileContainer>
      <CoulmnContainer>
        <InputSelect
          label="Time Zone"
          options={timeOptions}
          value={state.timeZone}
          setValue={e => dispatch({ action: 'timeZone', payload: e })}
          error={errors.timeZone}
        />
        <EditInput
          label="Password"
          value={state.password}
          setValue={e => dispatch({ action: 'password', payload: e })}
          password
          placeholder="******"
          error={!!errors.password}
          helperText={errors.password}
        />
        <EditInput
          label="Email"
          value={state.email}
          setValue={e => dispatch({ action: 'email', payload: e })}
          error={!!errors.email}
          helperText={errors.email}
        />
      </CoulmnContainer>
      <SaveButton>
        <PrimaryButton
          color="primary"
          onClick={handleEdit}
          disabled={isChanged}
          loading={loading}
          width="114px"
          height="36px"
        >
          Save
        </PrimaryButton>
      </SaveButton>
    </ProfileContainer>
  )
}

export default StudentProfileEditor
