import React from 'react'
import { Route } from 'react-router-dom'
import { useGlobalContext } from '../utils/globalContext'
import UnauthenticatedNavbar from '../components/UnauthenticatedNavbar'
import AuthenticatedNavbar from '../components/AuthenticatedNavbar'

const PublicRoute = ({ children, error, ...rest }) => {
  const { isSignedIn } = useGlobalContext()
  return (
    <Route
      {...rest}
      render={() => (!isSignedIn ? (
        <>
          <UnauthenticatedNavbar />
          {children}
        </>
      ) : (
        <>
          <AuthenticatedNavbar />
          {children}
        </>
      ))}
    />
  )
}

export default PublicRoute
