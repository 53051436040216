import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {
  FormContainer, FormHeader, FormContent, SmallInput, EmailInput,
} from './styles'
import useForm from '../../../../utils/useForm'
import { joinForm } from '../../../../utils/yup'
import { ADD_TEAM } from './graphql'
import PrimaryButton from '../../../../components/PrimaryButton'

const JoinOurTeamForm = () => {
  const [error, setError] = useState('')
  const {
    state, dispatch, validate, errors,
  } = useForm({ email: '', about: '', interest: '' }, joinForm)
  const [addTeam, { loading }] = useMutation(ADD_TEAM,
    {
      variables: {
        email: state.email,
        info: state.about,
        interest: state.interest,
      },
      onError: err => {
        if (err.graphQLErrors) {
          setError(err.graphQLErrors[0].message)
        }
      },
      // not sure if this is the best way to do this
      onCompleted: () => ((
        dispatch({ action: 'email', payload: '' }),
        dispatch({ action: 'about', payload: '' }),
        dispatch({ action: 'interest', payload: '' }))
      ),
    })
  const handleSubmit = async () => {
    if (await validate()) {
      addTeam()
    }
  }

  return (
    <FormContainer>
      <FormHeader> Want to join our team? </FormHeader>
      <FormContent>
        <EmailInput
          width="48%"
          value={state.email}
          setValue={e => dispatch({ action: 'email', payload: e })}
          label="Email Address"
          error={errors.email}
        />
        <SmallInput
          width="100%"
          value={state.about}
          setValue={e => dispatch({ action: 'about', payload: e })}
          label="Tell us a little about yourself."
          margin="dense"
          error={errors.about}
          multiline
        />
        <SmallInput
          width="100%"
          value={state.interest}
          setValue={e => dispatch({ action: 'interest', payload: e })}
          label="Why are you interested in joining SIJ2021?"
          margin="dense"
          error={errors.interest}
          multiline
          style={{ marginBottom: '55px' }}
        />
      </FormContent>
      { error
        && <p>{error}</p>}
      <PrimaryButton loading={loading} onClick={handleSubmit}>Submit</PrimaryButton>
    </FormContainer>
  )
}

export default JoinOurTeamForm
