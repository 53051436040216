import React from 'react'
import { useHistory } from 'react-router-dom'
import EventIcon from '@material-ui/icons/Event'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'

import { useGlobalContext } from '../../utils/globalContext'
import { ROUTE_PATHS } from '../../utils/constants'
import {
  Container, HStack, Logo,
} from './styles'
import logo from '../../assets/images/LogoWhite.svg'
import PrimaryButton from '../PrimaryButton'
// import LanguageSelect from '../LanguageSelect'
import { materialTheme } from '../../materialTheme'

const AuthenticatedNavbar = () => {
  const history = useHistory()
  const { setIsSignedIn } = useGlobalContext()

  const isAccount = window.location.href.includes(ROUTE_PATHS.ACCOUNT)
  const isTeam = window.location.href.includes(ROUTE_PATHS.OUR_TEAM)
  const isCalendar = window.location.href.includes(ROUTE_PATHS.CALENDAR)
  const isCourses = window.location.href.includes(ROUTE_PATHS.COURSES)


  return (
    <Container>
      <HStack>
        <PrimaryButton
          width="125px"
          height="82px"
          onClick={() => history.push(ROUTE_PATHS.ACCOUNT)}
          noBorderRadius
          style={isAccount && materialTheme.palette.navButtonActive}
        >
          Account
        </PrimaryButton>
        <PrimaryButton
          width="125px"
          height="82px"
          onClick={() => history.push(ROUTE_PATHS.OUR_TEAM)}
          noBorderRadius
          style={isTeam && materialTheme.palette.navButtonActive}
        >
          Team
        </PrimaryButton>
      </HStack>
      <Logo src={logo} onClick={() => history.push('/')} />
      <HStack>
        <PrimaryButton
          width="151px"
          height="82px"
          onClick={() => history.push(ROUTE_PATHS.CALENDAR)}
          startIcon={<EventIcon />}
          noBorderRadius
          style={isCalendar && materialTheme.palette.navButtonActive}
        >
          Calendar
        </PrimaryButton>
        <PrimaryButton
          width="151px"
          height="82px"
          onClick={() => history.push(ROUTE_PATHS.COURSES)}
          startIcon={<VideoLibraryIcon />}
          noBorderRadius
          style={isCourses && materialTheme.palette.navButtonActive}
        >
          Courses
        </PrimaryButton>
        <PrimaryButton
          width="80px"
          height="41px"
          margin="0 40px 0 10px"
          onClick={() => {
            localStorage.removeItem('token')
            history.push(ROUTE_PATHS.HOME)
            setIsSignedIn(false)
            window.location.reload(false)
          }}
        >
          Logout
        </PrimaryButton>
        {/* <LanguageSelect /> */}
      </HStack>
    </Container>
  )
}

export default AuthenticatedNavbar
