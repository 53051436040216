import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  Container, CourseName, Links, LinkText, Information, Text, Description,
} from './styles'
import PrimaryButton from '../PrimaryButton'
import { ROUTE_PATHS } from '../../utils/constants'

const CourseComponent = ({
  id, title, time, zoomLink, zoomPassword, instructor, shortDescription,
}) => {
  const history = useHistory()

  return (
    <Container id={id}>
      <CourseName>
        {title}
      </CourseName>
      <Links>
        <LinkText onClick={
          () => window.open(`${zoomLink}`)
        }
        >
          Zoom
        </LinkText>
        <LinkText to={{
          pathname: ROUTE_PATHS.VIDEOS,
          state: { id, tab: 1 },
        }}
        >
          About
        </LinkText>
      </Links>
      <Information>
        {instructor
          ? (
            <Text weight>
              Instructor:&nbsp;
              {instructor.name}
              ;&nbsp;
              {instructor.email}
              .
            </Text>
          )
          : <></>}
        <Description>
          {shortDescription}
        </Description>
        <Text weight>
          Time:&nbsp;
          {time}
        </Text>
        <Text weight style={{ marginTop: '7px', fontSize: '20px' }}>
          Password:&nbsp;
          {zoomPassword}
        </Text>
      </Information>
      <PrimaryButton
        onClick={() => history.push({
          pathname: ROUTE_PATHS.VIDEOS,
          state: { id, tab: 0 },
        })}
        margin="14px"
      >
        COURSE MATERIALS
      </PrimaryButton>
    </Container>
  )
}
export default CourseComponent
