import gql from 'graphql-tag'

export const LOGIN_STUDENT = gql`
    mutation studentLogin($email: EmailAddress!, $password: String!) {
    studentLogin(email: $email, password: $password) {
        token
        user {
            id
        }
    }
}
`
