import React from 'react'
import styled from 'styled-components'
import { styled as styledM } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export const InputStyle = styledM(({ ...rest }) => <TextField {...rest} />)({
  width: props => (props.inputWidth || '316px'),
  height: props => (props.height || '55px'),
  fontFamily: 'Gilroy',
  fontWeight: 'normal',
})

export const Container = styled.div`
  margin: 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: ${props => props.width || '450px'};
`

export const Label = styled.p`
  font-family: 'Gilroy';
  font-weight: normal;
`
