import React, { useState, useEffect } from 'react'
import { Container, Button, ScrollText } from './styles'
import ScrollButton from '../../../assets/images/ScrollTop.svg'

const GeneralScrollButton = () => {
  const [showScroll, setShowScroll] = useState(false)
  useEffect(() => {
    const ref = document.getElementById('app-wrapper')
    const checkScrollTop = () => {
      if (!showScroll && ref.scrollTop > 400) {
        setShowScroll(true)
      } else {
        setShowScroll(false)
      }
    }
    ref.addEventListener('scroll', checkScrollTop)
    return () => ref.removeEventListener('scroll', checkScrollTop)
  },
  [])
  const handleClick = () => document.getElementById('app-wrapper').scrollTo({ top: 0, behavior: 'smooth' })
  return (
    <Container show={showScroll} onClick={handleClick}>
      <Button src={ScrollButton} alt="totop" />
      <ScrollText>BACK TO TOP</ScrollText>
    </Container>
  )
}
export default GeneralScrollButton
