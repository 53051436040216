import React from 'react'
import { styled } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

export const ButtonStyle = styled(({ ...rest }) => <Button {...rest} />)({
  margin: props => props.margin || '0',
  width: props => (props.width || '316px'),
  height: props => (props.height || '55px'),
  borderRadius: props => (props.noBorderRadius ? '0px' : '4px'),
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  padding: '0',
})
