import styled from 'styled-components'

export const Container = styled.div`
display: flex;
min-height: 100vh;
flex-direction: column;
align-items: stretch;
`
export const ContentContainer = styled.div`
flex: 1;
`
