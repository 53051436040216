import styled from 'styled-components'
import theme from '../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 200px 0;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 316px;
  margin-top: 75px;
  margin-bottom: 10px;

  p {
    color: ${theme.colors.marine};
    margin: 0;
  }

  span {
    text-decoration: underline;
  }
`
