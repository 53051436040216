import styled from 'styled-components'
import theme from '../../../../theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const TitleSection = styled.div`
  padding: 40px 0 0 130px;

  h1 {
    color: ${theme.colors.marine};
    font-family: ${theme.fonts.primary};
    font-weight: bold;
    font-size: 36px;
    margin-bottom: 1em;
  }
`

export const TabPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 160px;
  font-family: ${theme.fonts.secondary};
`

export const InfoHeader = styled.h1`
  color: black;
  font-family: ${theme.fonts.secondary};
  font-weight: bold;
  font-size: 24px;
  margin-left: -30px;
  margin-bottom: 1em;
`
export const ResourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: ${theme.fonts.secondary};
  color:${theme.colors.fonts.againstLight};
  h1 {
    font-family: ${theme.fonts.secondary};
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: ${theme.colors.fonts.againstLight};
    margin: 11px;
  }

  p{
    font-family: ${theme.fonts.secondary};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;

    letter-spacing: 0.1px;

    color:${theme.colors.fonts.againstLight};
    margin-bottom: 1em;
  }

  a {
    font-family: ${theme.fonts.secondary};
    font-size: 22px;
    line-height: 24px;
    /* identical to box height, or 109% */

    letter-spacing: 0.1px;
    text-decoration-line: underline;
    color:${theme.colors.fonts.tertiary};
  }
  
  img {
    margin-bottom: 11px;
  }
`
