import React from 'react'
import { useHistory } from 'react-router-dom'

import PrimaryButton from '../../components/PrimaryButton'
import cover from '../../assets/images/Cover.png'
import HomeScrollButton from '../../components/ScrollButtons/HomeScrollButton'

import {
  Container, CoverImage, CoverTextSection, Welcome,
  ScrollButtonSection, CoverImageContainer, LeftTextContainer,
  RightTextContainter, HeaderText,
} from './styles'
import { ROUTE_PATHS } from '../../utils/constants'

const Home = () => {
  const history = useHistory()

  return (
    <Container>
      <CoverImageContainer>
        <CoverImage src={cover} />
        <CoverTextSection>
          <LeftTextContainer>
            <HeaderText>Summer In JAPAN</HeaderText>
            <h5>August 1st to 9th</h5>
          </LeftTextContainer>
          <RightTextContainter>
            <p>After eight years of hosting English seminars Oita, Japan, SIJ has gone online!</p>
            <p>
              {' '}
              All of our great workshops in Computer Science, Performing Arts,
              Public Speaking and Essay Writing are now available from this online portal.
            </p>
            <p>
              If you are interested in joining SIJ in 2021, please go to
              {' '}
              <a target="_blank" rel="noopener noreferrer" href="https://summerinjapan.com/en/">
                https://summerinjapan.com/en/
              </a>
              {' '}
              for more information.
            </p>
          </RightTextContainter>
        </CoverTextSection>
        <ScrollButtonSection>
          <HomeScrollButton />
        </ScrollButtonSection>
      </CoverImageContainer>
      <Welcome id="welcome"><span>Welcome SIJ 2021 Students to your homepage!</span></Welcome>
      <Welcome>{localStorage.getItem('token') ? 'You are now logged in.' : 'Log in with your email and the password provided by SIJ.'}</Welcome>
      <PrimaryButton
        onClick={() => history.push(localStorage.getItem('token') ? ROUTE_PATHS.COURSES : ROUTE_PATHS.LOGIN)}
      >
        {localStorage.getItem('token') ? 'COURSES' : 'LOG IN'}
      </PrimaryButton>
    </Container>
  )
}

export default Home
