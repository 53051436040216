import React from 'react'
import {
  Header, Container, Squiggle,
} from './styles'
import SquiggleBlue from '../../assets/images/SquiggleBlue.svg'
import SquiggleYellow from '../../assets/images/SquiggleYellow.svg'


const HeaderText = ({
  text, text2, color1, color2, squiggleColor, videos,
}) => (
  <Container videos={videos}>
    <Header color={color1} color2={color2}>
      {text}
      {' '}
      {text2 && <span>{text2}</span>}
    </Header>
    {!!squiggleColor
    && (
      <Squiggle src={squiggleColor === 'blue' ? SquiggleBlue : SquiggleYellow} />
    )}
  </Container>
)

export default HeaderText
