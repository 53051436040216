import React from 'react'
import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar'
import dayGridPlugin from '@fullcalendar/daygrid'
import './main.scss'


const Calendar = () => (
  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <div style={{ width: '942px', height: '941px' }}>
      <FullCalendar
        plugins={[googleCalendarPlugin, dayGridPlugin]}
        googleCalendarApiKey="AIzaSyBJZhdKPcoTRhtTdmiKiLrs1h3ykl2qltE"
        events={{ googleCalendarId: 'sijsummer2020@gmail.com' }}
      />
    </div>
  </div>
)

export default Calendar
