import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`

export const VideoArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 80vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`
export const BigText = styled.h2`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;

  /* Marine */

  color: ${({ theme }) => theme.colors.fonts.quarternary};
`
