import React from 'react'

import { InputStyle } from './styles'

const Input = ({
  value, setValue, label, error, password, color, ...rest
}) => (
  <InputStyle
    id={password ? 'standard-password-input' : 'standard-basic'}
    type={password ? 'password' : 'text'}
    value={value}
    onChange={e => setValue(e.target.value)}
    label={label}
    error={!!error}
    helperText={error && error}
    color={color || 'primary'}
    {...rest}
  />
)

export default Input
