import React from 'react'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import { grey } from '@material-ui/core/colors'
import ReactHtmlParser from 'react-html-parser'

import { Container, IconSection, AnnouncementSection } from './styles'


const AnnouncementWidget = ({ announcement, announce }) => (
  <Container>
    <IconSection>
      <AnnouncementIcon style={{ color: grey[400] }} />
    </IconSection>
    <AnnouncementSection>
      {announce
        ? (
          <div style={{ height: '70px', overflow: 'scroll' }}>
            {ReactHtmlParser(announcement)}
          </div>
        )
        : (
          <p>
            {announcement}
          </p>
        )}
    </AnnouncementSection>
  </Container>
)
export default AnnouncementWidget
