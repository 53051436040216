import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialThemeProvider, StylesProvider } from '@material-ui/core/styles'
import GlobalContext from './utils/globalContext'

import theme from './theme'
import { materialTheme } from './materialTheme'
import client from './client'
import PageWrapper from './containers/PageWrapper'
import AppRouter from './routes/AppRouter'
import ScrollButton from './components/ScrollButtons/GeneralScrollButton'

// Styles provider allows styled components to override material UI
// not sure if that's what we want on default, but makes it easier to tweak MUI
const App = () => (
  <div id="app-wrapper" style={{ height: '100vh', overflow: 'scroll' }}>
    <GlobalContext>
      <Router>
        <MaterialThemeProvider theme={materialTheme}>
          <StylesProvider injectFirst>
            <ThemeProvider theme={theme}>
              <ApolloProvider client={client}>
                <PageWrapper>
                  <AppRouter />
                  <ScrollButton />
                </PageWrapper>
              </ApolloProvider>
            </ThemeProvider>
          </StylesProvider>
        </MaterialThemeProvider>
      </Router>
    </GlobalContext>
  </div>
)

export default App
// to get the homepage scroll to work (-ish) :
// style={{ height: '100%', overflow: 'auto' }}
// helpful stack overflow:
// https://stackoverflow.com/a/18573599/1016383
