import gql from 'graphql-tag'

export const GET_COURSES = gql`
query getAllCourses($locale: SiteLocale!){
  allCourses(locale: $locale){
      id
      title
      instructor {
        id
        name
        email
      }
      time
      zoomLink
      zoomPassword
      shortDescription
    }
}
`

export const GET_ANNOUNCE = gql`
query announcement($locale:SiteLocale!){
  announcement(locale:$locale){
      id
      body
    }
}
`
