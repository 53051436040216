import React from 'react'
import {
  ProfileContainer, CoulmnContainer, NoSaveButton,
} from './styles'
import EditInput from '../../../../components/InputEditable'
import theme from '../../../../theme'


const StudentCourseDisplay = ({ course, workshop }) => (
  <ProfileContainer>
    <CoulmnContainer>
      <EditInput
        label="Course"
        value={course.courseName}
        icon={false}
        color={theme.colors.purple}
      />
      <EditInput
        label="Workshop"
        value={workshop.courseName}
        icon={false}
        color={theme.colors.purple}
      />
    </CoulmnContainer>
    <NoSaveButton />
  </ProfileContainer>
)

export default StudentCourseDisplay
