/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'

import { useQuery } from '@apollo/react-hooks'
import ReactHtmlParser from 'react-html-parser'
import MoonLoader from 'react-spinners/MoonLoader'
import {
  GET_LESSONS, GET_COURSE,
} from './graphql'

import VideoSelector from './components/VideoSelector'
import VideoDisplayer from './components/VideoDisplayer'
import CourseInfoMenu from './components/CourseInfoMenu'
import PdfViewer from '../../components/PdfViewer'

import {
  Container, VideoArea, LoadingContainer, BigText,
} from './styles'
import { useGlobalContext } from '../../utils/globalContext'

const Videos = ({ location: { state: { tab, id } } }) => {
  const [currentLesson, setCurrentLesson] = useState({ title: 'loading...', content: [], video: { url: 'loading', video: { thumbnailUrl: 'loading' } } })
  const { language } = useGlobalContext()
  const { data: course, loading: loadingCourse, error: courseError } = useQuery(GET_COURSE, {
    variables: { id, locale: language },
    partialRefetch: true,
  })

  const { data: courseLessons, loading, error } = useQuery(GET_LESSONS, {
    variables: { id, locale: language },
    onCompleted: () => {
      setCurrentLesson(courseLessons.allLessons[0])
    },
  })

  const contextHelper = el => {
    if (el.__typename === 'HeaderRecord') {
      return <h1>{el.text}</h1>
    }
    if (el.__typename === 'BodyRecord') {
      return ReactHtmlParser(el.text)
    }
    if (el.__typename === 'ImageRecord') {
      return <img src={el.media.url} alt={el.media.alt} style={{ objectFit: 'scale-down', maxWidth: '100%', alignSelf: 'center' }} />
    }
    return (
      <PdfViewer file={{ url: el.media.url }} />
    )
  }

  if (loadingCourse || loading) {
    return <LoadingContainer><MoonLoader loading /></LoadingContainer>
  }

  if (courseError || error) {
    return (
      <LoadingContainer>
        <BigText>Something went wrong while getting this lesson</BigText>
      </LoadingContainer>
    )
  }

  if (courseLessons.allLessons.length === 0) {
    return (
      <Container>
        <VideoSelector
          courseName={course.name}
        />
        <VideoArea>
          <VideoDisplayer
            url="none"
          />
          <CourseInfoMenu
            currentVideoName="No Videos yet"
            resources={<BigText>No resources yet</BigText>}
            instructorName={!!course.course.instructor && course.course.instructor.name}
            about={ReactHtmlParser(course.course.about)}
            syllabusUrl={course.course.syllabus && course.course.syllabus.url}
            zoomLink={course.course.zoomLink}
            zoomPassword={course.course.zoomPassword}
            tab={tab}
          />
        </VideoArea>
      </Container>
    )
  }

  const lessons = courseLessons.allLessons
  if (!currentLesson) {
    setCurrentLesson(lessons[0])
  }

  return (
    <Container>
      <VideoSelector
        courseName={course.name}
        lessons={lessons}
        currentLesson={currentLesson}
        setCurrentLesson={setCurrentLesson}
      />
      <VideoArea>
        {(currentLesson.video) && (
        <VideoDisplayer
          url={currentLesson.video}
          playing={false}
        />
        )}
        <CourseInfoMenu
          currentVideoName={currentLesson.title}
          resources={currentLesson.content.length === 0
            ? <BigText>No resources yet</BigText>
            : currentLesson.content.map(el => contextHelper(el))}
          instructorName={!!course.course.instructor && course.course.instructor.name}
          about={ReactHtmlParser(course.course.about)}
          syllabusUrl={course.course.syllabus && course.course.syllabus.url}
          zoomLink={course.course.zoomLink}
          zoomPassword={course.course.zoomPassword}
          tab={tab}
        />
      </VideoArea>

    </Container>
  )
}

export default Videos
