/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import MoonLoader from 'react-spinners/MoonLoader'
import { STUDENT_VIEWER } from './graphql'
import StudentProfileEditor from './components/StudentProfileEditor'
import StudentVitalsEditor from './components/StudentVitalsEditor'
import StudentCourseDisplay from './components/StudentCourseDisplay'
import {
  PageContainer, SubHeader, Description, HeaderBox, EditorContainer,
} from './styles'
import HeaderText from '../../components/HeaderText'
import theme from '../../theme'

const MyAccount = () => {
  const { data, loading } = useQuery(STUDENT_VIEWER, {
    onCompleted: () => {
    },
  })
  return (
    <PageContainer>
      <HeaderBox>
        <HeaderText text="My Account" squiggleColor="blue" color1={theme.colors.fonts.primary} />
      </HeaderBox>
      {loading
        ? (
          <div style={{ height: '50vh', paddingTop: '10vh' }}>
            <MoonLoader loading color={theme.colors.fonts.primary} />
          </div>
        ) : (
          <div>
            <EditorContainer>
              <SubHeader>Courses</SubHeader>
              <Description>Here are the courses you are enrolled in. Find them in the Courses page.</Description>
              <StudentCourseDisplay
                course={data.studentViewer.course}
                workshop={data.studentViewer.workshop}
              />
            </EditorContainer>
            <EditorContainer>
              <SubHeader>Profile</SubHeader>
              <Description>Change your account information.</Description>
              <StudentProfileEditor
                emailAddress={data.studentViewer.email}
                timeZone={data.studentViewer.timeZone}
              />
            </EditorContainer>
            <EditorContainer>
              <SubHeader>Vitals</SubHeader>
              <Description>To change these, contact an instructor.</Description>
              <StudentVitalsEditor
                firstName={data.studentViewer.firstName}
                lastName={data.studentViewer.lastName}
                age={data.studentViewer.age}
              />
            </EditorContainer>
          </div>
        )}
    </PageContainer>
  )
}

export default MyAccount
