import React, { useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { useHistory } from 'react-router-dom'

import HeaderText from '../../components/HeaderText'
import Input from '../../components/Input'
import { ROUTE_PATHS } from '../../utils/constants'
import { useGlobalContext } from '../../utils/globalContext'
import { Container, InputContainer, LinkContainer } from './styles'
import useForm from '../../utils/useForm'
import { LoginYupSchema } from '../../utils/yup'
import PrimaryButton from '../../components/PrimaryButton'
import { LOGIN_STUDENT } from './graphql'

const Login = () => {
  const {
    state, dispatch, validate, errors,
  } = useForm({
    email: '',
    password: '',
  }, LoginYupSchema)

  const [DBError, setError] = useState('')

  const history = useHistory()

  const { setIsSignedIn } = useGlobalContext()

  const [loginStudent, { loading }] = useMutation(
    LOGIN_STUDENT,
    {
      variables: { email: state.email.trim(), password: state.password },
      onCompleted: () => {
        setIsSignedIn(true)
        history.push(ROUTE_PATHS.HOME)
        window.location.reload(false)
      },
      onError: err => {
        if (err.graphQLErrors.length) {
          setError(err.graphQLErrors[0].message)
        }
      },
    },
  )


  const handleSubmit = async () => {
    if (await validate()) {
      loginStudent()
    }
  }

  return (
    <Container>
      <HeaderText text="Student Login" />
      <InputContainer>
        <Input
          value={state.email}
          setValue={val => dispatch({ action: 'email', payload: val })}
          label="Email"
          error={errors.email || DBError}
        />
        <Input
          value={state.password}
          setValue={val => dispatch({ action: 'password', payload: val })}
          label="Password"
          error={errors.password || DBError}
          password
        />
      </InputContainer>
      <LinkContainer>
        <p>Are you an instructor?</p>
        <a href="https://admin.sijonline.com">Login with an instructor account</a>
      </LinkContainer>
      <PrimaryButton onClick={handleSubmit} loading={loading}>CONTINUE</PrimaryButton>
    </Container>
  )
}

export default Login
