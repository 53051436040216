import styled from 'styled-components'

export const Greeting = styled.div`
  height: 330px;
  opacity: ${props => (props.night ? 0.2 : 0.6)};
  background-color: white;
  position: relative;
  z-index: 1;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  margin-top: -150px;
  padding-bottom: 62px;
  align-items: center;
  justify-content: center;
`
export const CourseContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1050px;
  width: 90vw;
  justify-content: center;
`
export const TopContainer = styled.div`
  position: relative;
  z-index: 2;
  top: -210px;
  height: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const ErrorContainer = styled.div`
  width: 100vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 56px;
`
