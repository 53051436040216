import styled from 'styled-components'


export const ProfileContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 782px;
    height: 305px;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.colors.background.primary};
    
`
export const RowContainer = styled.div`
    display: flex;
    margin-left: 119px;
`
export const CoulmnContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 109px;
`
export const FieldName = styled.p`
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 43px;

    color: ${({ theme }) => theme.colors.fonts.againstLight};
`

export const SaveButton = styled.div`
    margin-bottom: 18px;
    margin-right: 15px;
    margin-left: auto;
    margin-top: auto;
`
