import { createMuiTheme } from '@material-ui/core/styles'
import theme from './theme'

export const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: theme.colors.marine,
    },
    secondary: {
      main: theme.colors.peacock,
    },
    error: {
      main: theme.colors.error,
    },
    purpleBackground: {
      backgroundColor: theme.colors.purple,
      color: '#fff',
    },
    purpleAccent: {
      color: theme.colors.purple,
    },
    whiteBackground: {
      backgroundColor: '#fff',
      color: theme.colors.marine,
    },
    navButtonActive: {
      backgroundColor: '#5f2ced',
      color: '#fff',
      borderBottom: '4px solid #fff',
    },
  },
  typography: {
    fontFamily: [
      'Gilroy',
      'Raleway',
      'Sans Serif',
    ],
  },
})
