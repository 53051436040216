import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'

export const InstructorCard = styled(Card)`
    width: ${props => props.width || '234px'};
    height: ${props => props.height || '333px'};
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 20px ${({ theme }) => theme.colors.accents.shadow};
`
export const LearnMore = styled(Link)`
    font-family: ${({ theme }) => theme.fonts.tertiary};
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 171%;
    letter-spacing: 1.25px;
    color: ${({ theme }) => theme.colors.fonts.tertiary};
`
export const InstructorName = styled(Typography)`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 107%;
    color: ${({ theme }) => theme.colors.fonts.secondary};
`
export const ShortBio = styled(Typography)`
    font-family: ${({ theme }) => theme.fonts.secondary};
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.fonts.secondary};
    max-height: 70%;
    overflow: auto;
`
export const Content = styled(CardContent)`
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
`
export const Actions = styled(CardActions)`
    flex: 0;
    padding-left: 16px;
    padding-top: 0px;
`

export const Media = styled(CardMedia)`
    height: ${props => `${props.width * (3 / 4)}px` || '43%'};
    width: 100%;
    &.MuiCardMedia-img{
        object-fit: scale-down;
    }
    background-color: ${({ theme }) => theme.colors.fonts.tertiary};
`
