import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 82px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  position: relative;
`

export const Logo = styled.img`
  width: 47px;
  height: 43px;
  justify-self: left;
  position: absolute;
  left: calc(50% - 23px);
  cursor: pointer;
`
