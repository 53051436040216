import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Input from '../../../../components/Input'
import Button from '../../../../components/PrimaryButton'

export const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 64px;
    margin-bottom: 217px;
    align-items: center;
    justify-content: center;
`
export const FormHeader = styled(Typography)`
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: ${({ theme }) => theme.colors.purple};
    margin-bottom: 100px;
`
export const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`

export const SmallInput = styled(Input)`
    margin: 0px;
    margin-bottom: 10px;
    flex: 1;

    & input {
        font-size: 14px;
        line-height: 19px;
        color: ${({ theme }) => theme.colors.fonts.primary};
    }
    .MuiInput-underline {
            &:before{
                border-color: ${({ theme }) => theme.colors.fonts.primary};
            }
        }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
        border-color: ${({ theme }) => theme.colors.fonts.primary};
    }
    .MuiInputBase-input{
        color: ${({ theme }) => theme.colors.fonts.primary};
    }

    & .MuiInputLabel-root {
        font-size: 14px;
        :not(.Mui-error) {
            color: ${({ theme }) => theme.colors.fonts.quarternary};
            ${props => props.value && `color: ${props.theme.colors.fonts.primary};`}

            &.Mui-focused{
            color: ${({ theme }) => theme.colors.fonts.primary};
            } 
        }
    }  
`
export const EmailInput = styled(Input)`
    margin: 0px;
    margin-bottom: 40px;
    align-self: flex-start;

    & input {
        font-size: 22px;
        line-height: 24px;
       
    }
    .MuiInput-underline {
            &:before{
                border-color: ${({ theme }) => theme.colors.fonts.primary};
            }
        }
    .MuiInput-underline:hover:not(.Mui-disabled):before{
        border-color: ${({ theme }) => theme.colors.fonts.primary};
    }
    .MuiInputBase-input{
        color: ${({ theme }) => theme.colors.fonts.primary};
    }

    & .MuiInputLabel-root {
        font-size: 22px;
        
        :not(.Mui-error) {
            color: ${({ theme }) => theme.colors.fonts.quarternary};
            ${props => props.value && `color: ${props.theme.colors.fonts.primary};`}

            &.Mui-focused{
            color: ${({ theme }) => theme.colors.fonts.primary};
            } 
        }
    }  

`
export const FormButton = styled(Button)`
    margin-top: 58px;
`
