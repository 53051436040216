import React from 'react'
import HeaderText from '../../components/HeaderText'
import Calendar from '../../components/Calendar'

import { Container, Divider } from './styles'

const CalendarScreen = () => (
  <Container>
    <HeaderText
      text="Calendar"
      squiggleColor="yellow"
    />
    <Divider />
    <Calendar />
  </Container>
)

export default CalendarScreen
