import gql from 'graphql-tag'

const EDIT_STUDENT = gql`
    mutation editStudent($input: EditStudentInput!){
        editStudent(input: $input){
            id
            email
            timeZone
        }
    }
`
export default EDIT_STUDENT
