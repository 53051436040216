import React from 'react'
import { styled } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

export const InputStyle = styled(({ ...rest }) => <TextField {...rest} />)({
  marginBottom: '20px',
  width: props => (props.width || '316px'),
  height: props => (props.height || '55px'),
  fontFamily: 'Gilroy',
  fontWeight: 'normal',
})
