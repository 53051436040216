import gql from 'graphql-tag'

export const VIEWER = gql`
  query StudentViewer{
    studentViewer {
      id
      firstName
      lastName
      age
      timeZone
      email
      course {
        type
        courseName
        time
      }
      workshop {
        type
        courseName
        time
      }
    }
  }
`
