import styled from 'styled-components'

export const Container = styled.div`
  background-color: black;
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
`

export const PlayButton = styled.img`
  width: 55px;
  height: 66px;
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.15));
`
